@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");

.text {
  font-family: 'Noto Sans', sans-serif;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

li {
  font-size: 14px;
  margin-left: 10px;
  list-style-type: disc;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

/* .home-name {
  font-size: 150px;
} */